// Default menu settings configurations

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  isStarterkitExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[]
  };
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {  
    items: [
      
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: '/dashboard/sales',
        badge: { type: 'badge-info', value: '3' },
      },
      {
        title: 'POS',
        icon: 'la-credit-card',
        page: '/pos',
      },
      {
        title: 'Logbuch',
        page: '/logs',
        icon: 'la-list'
      },
      {
        title: 'Kino GLS',
        icon: 'la-television',
        page: 'null',
        submenu: {
          items: [
            
            {
              title: 'Kino',
              icon: 'la-television',
              page: 'null',
              submenu: {
                items: [
                  /*
                  {
                    title: 'Verkauf',
                    page: '/pos',
                  },
                  */
                  {
                    title: 'Reservierung',
                    page: '/reservation'
                  },
                  {
                    title: 'Karten buchen',
                    page: '/ticket'
                  },
                  {
                    title: 'Tagesabschluss',
                    page: '/day-report'
                  },
                  {
                    title: 'Einkauf Handkasse',
                    page: '/logs/neu/logs-base'
                  },
                  /*
                  {
                    title: 'Logbuch',
                    page: '/logs'
                  },
                  */
                ]
              }
            },
            {
              title: 'Filmterminierung',
              icon: 'la-film',
              page: 'null',
              submenu: {
                items: [
                  {
                    title: 'Filme',
                    page: 'movie-manager'
                  },
                  {
                    title: 'Vorstellungen',
                    page: 'simple-scheduler'
                  }
                ]
              }
            },
            {
              title: 'Warenwirtschaft',
              icon: 'la-truck',
              page: 'null',
              submenu: {
                items: [
                  {
                    title: 'Waren',
                    page: 'goods'
                  },
                  {
                    title: 'Lieferung',
                    page: 'null'
                  },
                  {
                    title: 'Inventur',
                    page: 'null'
                  },
                ]
              }
            },
            {
              title: 'CRM',
              icon: 'la-users',
              page: 'null',
              submenu: {
                items: [
                  {
                    title: 'Kunden',
                    page: '/kunden'
                  },
                  {
                    title: 'Social-Media',
                    page: 'null'
                  },
                  {
                    title: 'Marketing',
                    page: 'null'
                  },
                ]
              }
            },
            {
              title: 'Abrechnung',
              icon: 'la-calculator',
              page: 'null',
              submenu: {
                items: [
                  {
                    title: 'Verleihabrechnung',
                    page: '/rental-listing'
                  },
                  {
                    title: 'Kartenabrechnung',
                    page: '/ticket-listing'
                  },
                  {
                    title: 'FFA',
                    page: '/ffa-listing'
                  },
                  {
                    title: 'GEMA',
                    page: '/gema-listing'
                  },
                  {
                    title: 'Förderantrag',
                    page: '/grant-application'
                  },
                  {
                    title: 'Rentrakmeldung',
                    page: '/rentrak-listing'
                  },
                  {
                    title: 'Kassenbuch (Hist)',
                    page: '/cashbook'
                  },
                  {
                    title: 'Karteneinsatz (bei Kartenabr.)',
                    page: 'null'
                  },
                  {
                    title: 'Archiv',
                    page: '/archive'
                  }
                ]
              }
            },
            {
              title: 'Stammdaten',
              page: 'null',
              icon: 'la-home',
              submenu: {
               items: [
                {
                  title: 'Verleiher',
                  page: '/base/rental-manager'
                },
                {
                  title: 'Lieferanten',
                  page: '/base/supplier-manager'
                },
                {
                  title: 'Karten',
                  page: '/base/ticket-manager'
                },
                {
                  title: 'Waren',
                  page: '/base/product-manager'
                },
                {
                  title: 'Konten',
                  page: '/base/bank-account-manager'
                },
               ] 
              }

            },
          ]
        },
      },
      /*
      {
        title: 'Septer',
        icon: 'la-home',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Objektemanager',
              icon: 'la-city',
              page: 'septer/immo-objects'
            }
          ]
        }
      },
      {
        title: 'WLB',
        icon: 'la-leaf',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Fuhrparkmanager',
              icon: 'la-truck',
              page: 'null'
            }
          ]
        }
      },
      */
      {
        title: 'Tools',
        icon: 'la-cogs',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Todo',
              page: '/todo-app'
            },
          ]
        }
      },
      {
        title: 'Stammdaten',
        icon: 'la-list',
        page: '/base',
        submenu: {
          items: [
            {
              title: 'Benutzer',
              page: '/base/user-manager'
            },
          ]
        }
      },
      {
        title: 'Admin',
        icon: 'la-cog',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'System',
              page: 'system-setting'
            },
            {
              title: 'Einstellungen',
              page: 'account-setting'
            },
            {
              title: 'Backup',
              page: 'backup-manager'
            },
          ]
        }
      },
 
    ]
  },

  vertical_menu: {
    items: [
      
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: '/dashboard/sales',
        badge: { type: 'badge-info', value: '3' },
      },
      {
        title: 'Kino GLS',
        icon: 'la-television',
        page: 'null',
        submenu: {
          items: [
            
            {
              title: 'Kino',
              icon: 'la-television',
              page: 'null',
              submenu: {
                items: [
                  {
                    title: 'Verkauf',
                    page: '/pos',
                  },
                  {
                    title: 'Reservierung',
                    page: '/reservation'
                  },
                  {
                    title: 'Karten buchen',
                    page: '/ticket'
                  },
                  {
                    title: 'Tagesabschluss',
                    page: '/day-report'
                  },
                  {
                    title: 'Kassenbuch',
                    page: '/cb'
                  },
                  {
                    title: 'Logbuch',
                    page: '/logs'
                  },
                ]
              }
            },
            {
              title: 'Filmterminierung',
              icon: 'la-film',
              page: 'null',
              submenu: {
                items: [
                  {
                    title: 'Filme',
                    page: 'movie-manager'
                  },
                  {
                    title: 'Vorstellungen',
                    page: 'scheduler'
                  }
                ]
              }
            },
            {
              title: 'Warenwirtschaft',
              icon: 'la-truck',
              page: 'null',
              submenu: {
                items: [
                  {
                    title: 'Waren',
                    page: 'null'
                  },
                  {
                    title: 'Lieferung',
                    page: 'null'
                  },
                  {
                    title: 'Inventur',
                    page: 'null'
                  },
                ]
              }
            },
            {
              title: 'CRM',
              icon: 'la-users',
              page: 'null',
              submenu: {
                items: [
                  {
                    title: 'Kunden',
                    page: '/kunden'
                  },
                  {
                    title: 'Social-Media',
                    page: 'null'
                  },
                  {
                    title: 'Marketing',
                    page: 'null'
                  },
                ]
              }
            },
            {
              title: 'Abrechnung',
              icon: 'la-calculator',
              page: 'null',
              submenu: {
                items: [
                  {
                    title: 'Verleihabrechnung',
                    page: '/rental-listing'
                  },
                  {
                    title: 'Kartenabrechnung',
                    page: '/ticket-listing'
                  },
                  {
                    title: 'FFA',
                    page: '/ffa-listing'
                  },
                  {
                    title: 'GEMA',
                    page: '/gema-listing'
                  },
                  {
                    title: 'Förderantrag',
                    page: '/grant-application'
                  },
                  {
                    title: 'Rentrakmeldung',
                    page: '/rentrak-listing'
                  },
                  {
                    title: 'Kassenbuch (Hist)',
                    page: '/cashbook'
                  },
                  {
                    title: 'Karteneinsatz (bei Kartenabr.)',
                    page: 'null'
                  },
                  {
                    title: 'Archiv',
                    page: '/archive'
                  }
                ]
              }
            },
          ]
        },
      },
      {
        title: 'Septer',
        icon: 'la-home',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Objektemanager',
              icon: 'la-city',
              page: 'septer/immo-objects'
            }
          ]
        }
      },
      {
        title: 'WLB',
        icon: 'la-leaf',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Fuhrparkmanager',
              icon: 'la-truck',
              page: 'null'
            }
          ]
        }
      },
      {
        title: 'Stammdaten',
        icon: 'la-list',
        page: '/base',
        submenu: {
          items: [
            {
              title: 'Benutzer',
              page: '/base/user-manager'
            },
            {
              title: 'Verleiher',
              page: '/base/rentals-manager'
            },
            {
              title: 'Lieferanten',
              page: '/base/suplier-manager'
            },
            {
              title: 'Karten',
              page: '/base/ticket-manager'
            },
            {
              title: 'Waren',
              page: '/base/product-manager'
            },
            {
              title: 'Konten',
              page: '/base/bank-account-manager'
            },
          ]
        }
      },
      {
        title: 'Admin',
        icon: 'la-cog',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'System',
              page: 'system-setting'
            },
            {
              title: 'Einstellungen',
              page: 'account-setting'
            },
            {
              title: 'Backup',
              page: 'backup-manager'
            },
          ]
        }
      },
 
    ]
  }

};





