import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserObject } from 'src/app/_interfaces/cinema/UserObject'

const API_URL = environment.prismaApiUrl +'/api/settings';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  currentObject: any;
  refreshed: any;
  isNew: Boolean = false;

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get(API_URL);
  }

  getPublicSetting(): Observable<any> {
    return this.http.get(API_URL + '/public');
  }
 
  show(id: any): Observable<any> {
    this.currentObject = this.http.get(API_URL + '/' + id);
    return this.currentObject;
  }

  update(object: any): Observable<any> {
    return this.http.put<UserObject>(API_URL + '/' + object.id, {data: object});
  }

  getThemeSettings(): Observable<any> {
    return this.http.get(API_URL + '/theme_settings');
  }

  getCurrentObject() {
    return this.currentObject;
  }
}
